const smtPageDirectoryComponent = {
    section: {
        title: {
            color: {
                default: 'theme.500',
                _dark: 'theme.200'
            }
        }
    }
}

export default smtPageDirectoryComponent;