import React from 'react'

import {Box, chakra} from '@chakra-ui/react'

export const Logo = (props: any) => {
  return (
    <chakra.svg
      xmlns="http://www.w3.org/2000/svg"
      width="full"
      height="full"
      viewBox="0 0 475 475"
      sx={{
        '.squarel': {
          fillRule: 'evenodd',
          clipRule: 'evenodd',
          fill: 'none'
        },
        '.k': {
          stroke: '#bae0e2',
          strokeWidth: "15px",
          //strokeDasharray: "5"
        }
      }}
      {...props}>
      <g id="Ebene_1-2">
        <g id="layer1">
          <path
            id="Fill_1"
            className="squarel"
            d="M425.2,49.62C380.42,4.99,316.03,0,237.33,0S94.09,5.05,49.31,49.78,0,158.9,0,237.59s4.53,143.14,49.31,187.87c44.78,44.73,109.23,49.26,188.02,49.26s143.24-4.53,188.02-49.26c44.78-44.73,49.31-109.12,49.31-187.87,.05-78.8-4.58-143.29-49.46-187.97h0Z"
          />
        </g>
        <path
          className="k"
          d="M 201.10,160.44
          C 199.43,171.25 194.66,181.29 191.21,191.61
            191.21,191.61 171.70,248.01 171.70,248.01
            171.70,248.01 151.13,307.39 151.13,307.39
            151.13,307.39 136.08,350.43 136.08,350.43
            130.73,364.56 131.85,366.55 120.95,366.76
            120.95,366.76 104.62,366.76 104.62,366.76
            101.74,366.76 96.13,367.14 93.84,365.29
            90.37,362.48 91.44,355.66 92.39,351.92
            92.39,351.92 104.13,317.78 104.13,317.78
            104.13,317.78 156.57,164.89 156.57,164.89
            156.57,164.89 170.92,121.84 170.92,121.84
            172.25,117.87 175.39,106.28 178.39,104.02
            180.87,102.17 186.20,102.55 189.23,102.55
            189.23,102.55 266.42,102.55 266.42,102.55
            293.43,102.55 317.27,101.87 342.12,114.81
            370.37,129.53 375.11,152.16 374.77,181.22
            374.65,191.42 372.06,200.61 366.83,209.42
            360.81,219.55 351.95,225.76 342.12,231.69
            365.48,237.99 379.80,255.34 383.45,279.18
            383.45,279.18 383.45,301.45 383.45,301.45
            383.50,315.85 377.61,333.40 368.35,344.49
            345.31,372.09 307.85,371.21 275.32,371.21
            275.32,371.21 224.85,371.21 224.85,371.21
            224.85,371.21 187.74,371.21 187.74,371.21
            184.79,371.21 179.12,371.56 176.76,369.70
            173.83,367.38 174.39,361.27 174.39,357.85
            174.39,357.85 174.39,338.56 174.39,338.56
            179.98,337.77 198.15,335.13 201.07,330.68
            202.90,327.89 202.58,322.55 202.59,319.26
            202.59,319.26 202.59,213.87 202.59,213.87
            202.59,201.77 203.89,170.26 201.10,160.44 Z
          M 253.06,215.36
          C 268.80,215.36 299.47,217.50 312.43,209.43
            333.17,196.52 332.54,160.94 310.95,149.18
            297.83,142.03 268.61,144.11 253.06,144.11
            253.06,144.11 253.06,215.36 253.06,215.36 Z
          M 253.06,329.65
          C 267.18,329.65 302.29,331.15 313.91,327.28
            318.92,325.62 323.40,322.86 327.12,319.11
            340.20,305.90 339.06,273.01 325.51,260.39
            318.48,253.84 309.73,252.51 300.56,252.47
            300.56,252.47 253.06,252.47 253.06,252.47
            253.06,252.47 253.06,329.65 253.06,329.65 Z"
        />
      </g>
    </chakra.svg>
  )
}

export default Logo
